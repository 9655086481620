import React, { useContext } from 'react'
import { OTable } from '../styledComponents'
import { moneyFormatter } from 'pageComponents/_common/util/formatters'
import Context from 'setup/context'
import { useCheckout2 } from 'setup/CheckoutProviderFields';
import { getImagePath } from 'pageComponents/_common/helpers/generalHelperFunctions';
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants';
import { format as dateFormat, format } from 'date-fns';
import { CUSTOMIZATION_KIND } from 'pageComponents/_common/constants/customizationConstants';
import { Badge, Chip, Tooltip } from '@mui/material';
import { AccessTimeRounded, LocalShippingOutlined } from '@mui/icons-material';
import { ThemeProvider } from 'styled-components';
import { InfoAlert } from 'styles/alerts';

const ItemRow = (props) => {
	const {
		cartItem,
		userInfo,
		scheduleItem
	} = props;

 return (
	<tr key={cartItem.LineNumber}>
		<td className="image">
			<span>
				#{cartItem.LineNumber}
			</span>
			<img width="100" src={getImagePath(cartItem.ImageUrl)} alt={cartItem.ItemId} />
			<span>AHC{cartItem.InvMastUid}</span>
		</td>
		<td className="itemDesc">
			<span className="itemId">
				{cartItem.CustomerPartNumber ? cartItem.CustomerPartNumber : cartItem.ItemId}
			</span>
			{cartItem.CustomerPartNumber && <><br/><small>{cartItem.ItemId} </small></>}
			<br />
			<span>
				{moneyFormatter.format(cartItem.SaleUnitPrice)} / {cartItem.UnitOfMeasure}
			</span>
			<span>
				x{cartItem.Quantity}
				{cartItem.Customizations && cartItem.Customizations.length > 0 && <span><small>Length: {cartItem.Customizations.find(i => i.CustomizationKind === CUSTOMIZATION_KIND.CutToLength)?.DimLengthX}{cartItem.Customizations.find(i => i.CustomizationKind === CUSTOMIZATION_KIND.CutToLength)?.UnitOfMeasure.toLowerCase()}</small></span>}
			</span>
			{userInfo?.isImpersonatorUser && (
				<>
					<br />
					<span>{cartItem.Describe}</span>
					{scheduleItem?.RequestedDate && <><br /><small>Requested Date: {format(scheduleItem.RequestedDate, DATE_FORMAT_DISPLAY)}</small></>}
					<br />
					{cartItem.IsDropship && (
						<>
							<span>This item will be dropshipped.</span>
							<br />
						</>
					)}
				</>
			)}
		</td>
		<td className="subTotal">
			<span className="lineTotal">
				{moneyFormatter.format(cartItem.Quantity * cartItem.SaleUnitPrice)}
			</span>
			{cartItem.IsBackorder && <><br /><Chip style={{padding: "3px", margin: "2px"}} label={"Backorder"} icon={<AccessTimeRounded style={{margin: "2px"}}/>} /></>}
			{cartItem.SourceNote &&  <><br /><Chip style={{padding: "3px", margin: "2px"}} label={cartItem.SourceNote} icon={<AccessTimeRounded style={{margin: "2px"}}/>} /></>}
		</td>
	</tr>);
}

export default function ItemShippingTable(props) {
    const { userInfo } = useContext(Context);
    const { checkoutInitializeData, checkoutEditableFields, shippingGroups } = useCheckout2();
    return <>
	    {(shippingGroups && shippingGroups.length > 0) && 
			<OTable>
				{shippingGroups.map(group => {
					return (
						<tbody key={group.GroupId} className="shipGroups">
							<tr><td colSpan="3"><h5>Shipment {group.GroupId}, est. ship date {dateFormat(new Date(group.EstimatedShipDate), DATE_FORMAT_DISPLAY)}</h5></td></tr>
							{group.Items?.map(item => { 
								const cartItem = checkoutInitializeData.ShoppingCartItems.find(i => i.LineNumber === item.LineNumber);
								
								const scheduleItem = checkoutEditableFields.ShippingChoices_ShippingSchedule.find(i => i.LineNumber === item.LineNumber);

								return <ItemRow key={cartItem.LineNumber} cartItem={{...cartItem, Quantity: item.Quantity, SourceNote: item.SourceNote }} userInfo={userInfo} scheduleItem={scheduleItem} />
							})}
						</tbody>)
					}
				)}
			</OTable>}

        {/* If shipping groups is not populated, just show the cart items and skip the shipment headings */}
        {(!shippingGroups || shippingGroups.length === 0) && <OTable>
            <tbody className="shipGroups">
                <tr><td colSpan="3">Get estimated shipping costs and shipments by clicking Get Shipping Rates</td></tr>
                {checkoutInitializeData.ShoppingCartItems.map(cartItem => { 
                    return <ItemRow key={cartItem.LineNumber} cartItem={cartItem} userInfo={userInfo} scheduleItem={null} />
                })}
            </tbody>
        </OTable>}
    </>
}
