import React, { useContext, useEffect, useState } from 'react'
import AdditionalShippingOptions from '../components/AdditionalShippingOptions'
import AddressSelect from '../components/address/AddressSelect'
import AddressViewEdit from '../components/address/AddressViewEdit'
import {
    AreaContentRow, AreaHeading, HeaderActionButton, HorizontalPack, Area, LayoutArea,
} from '../styledComponents'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import MyContext from 'setup/context'
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants'
import { format, isSameDay } from 'date-fns'
import { CheckCircle } from '@mui/icons-material'
import { Divider } from '@mui/material'

const todayDate = new Date();

export default function ShippingAddress() {
    const {
        checkoutInitializeData,
        checkoutEditableFields,
        validationStatus,
        inPageRefs,
        openEditors,
		showShippingAddressEditor,
		setShowShippingAddressEditor,
		showShippingOptionsModal,
		setShowShippingOptionsModal,
    } = useCheckout2();

    const { userInfo } = useContext(MyContext);

    const [showShippingAddressPicker, setShowShippingAddressPicker] = useState(false);

    function getShippingAddr() {
        return {
            P21Id: checkoutEditableFields.ShippingChoices_P21Id,
            Name: checkoutEditableFields.ShippingChoices_Name,
            FirstName: checkoutEditableFields.ShippingChoices_FirstName,
            LastName: checkoutEditableFields.ShippingChoices_LastName,
			Attn: checkoutEditableFields.ShippingChoices_Attn,
            Address1: checkoutEditableFields.ShippingChoices_Address1,
            Address2: checkoutEditableFields.ShippingChoices_Address2,
            City: checkoutEditableFields.ShippingChoices_City,
            State: checkoutEditableFields.ShippingChoices_State,
            Zip: checkoutEditableFields.ShippingChoices_Zip,
            Country: checkoutEditableFields.ShippingChoices_Country,
            Phone: checkoutEditableFields.ShippingChoices_Phone,
            PhoneExt: checkoutEditableFields.ShippingChoices_PhoneExt,
            DeliveryInstructions: checkoutEditableFields.ShippingChoices_DeliveryInstructions,
            PreferredPackingBasis: checkoutEditableFields.ShippingChoices_PreferredPackingBasis,
            PreferredCarrierName: checkoutEditableFields.ShippingChoices_PreferredCarrierName,
            IsCollect: checkoutEditableFields.ShippingChoices_IsCollect,
            CollectAccount: checkoutEditableFields.ShippingChoices_CollectAccount,
        };
    }

    const [selectedShippingAddress, setSelectedShippingAddress] = useState(getShippingAddr());

    function selectAddress(addr) {
        //Deliberately does not update checkoutEditableFields directly
        console.log("setSelectedAddress", addr);
        setSelectedShippingAddress({ ...addr });
        setShowShippingAddressEditor(true);
    }

    function newAddressCallback() {
        setSelectedShippingAddress({
            P21Id: 0,
            Name: '',
            FirstName: '',
            LastName: '',
			Attn: '',
            Address1: '',
            Address2: '',
            City: '',
            State: '',
            Zip: '',
            Country: '',
            Phone: '',
            PhoneExt: ''
        });
        setShowShippingAddressEditor(true);
    }
	
    //Add watcher to update the address displayed, if it changes (because of the validation call)
    useEffect(() => {
        setSelectedShippingAddress(getShippingAddr());
    }, [checkoutEditableFields]);

    const preferredCarrier = checkoutInitializeData.ShippingAddresses?.find(a => a.P21Id === checkoutEditableFields.ShippingChoices_P21Id)?.PreferredCarrierName;
	const shipToValid = validationStatus.IsShipToValid && !openEditors.shippingAddress && !openEditors.shippingOptions;

    return (
        <Area>
            <AreaHeading ref={inPageRefs.shippingAddressSectionRef}>
                <h1>Shipping Address</h1>
                {!!userInfo && (
                    <HeaderActionButton id={`shipping-AddressBookBtn`} onClick={() => setShowShippingAddressPicker(!showShippingAddressPicker)}>
                        {showShippingAddressPicker ? 'Done' : 'Address Book...'}
                    </HeaderActionButton>
                )}
                <CheckCircle titleAccess={shipToValid ? 'Ship To section is valid' : 'Ship To section is not valid'} label='Ship to is Valid' htmlColor={shipToValid ? 'green' : '#CDC'} />
            </AreaHeading>
            <LayoutArea>
                {showShippingAddressPicker ? (
                    <AddressSelect
						addressType="shipping"
                        hide={() => setShowShippingAddressPicker(false)}
                        title="Shipping Address Book"
                        addressList={checkoutInitializeData.ShippingAddresses}
                        addressSelectedCallback={(addr) => selectAddress(addr)}
                        newAddressCallback={newAddressCallback}
                        currentSelectedAddressId={selectedShippingAddress.P21Id}
                    />

                ) : (showShippingOptionsModal ? (
                    <AdditionalShippingOptions
                        hide={() => setShowShippingOptionsModal(false)}
                        title="Advanced Shipping Options"
                        userInfo={userInfo}
                    />
                ) : (
                    <>
                        <AreaContentRow>
                            <AddressViewEdit
                                address={selectedShippingAddress}
                                addressUpdatedCallback={selectAddress}
                                addressType="shipping"
                                showEditor={showShippingAddressEditor}
                                setShowEditor={setShowShippingAddressEditor}
                            />
                        </AreaContentRow>
						{!showShippingAddressEditor && <>
							<Divider flexItem />
							<AreaContentRow>
								<HorizontalPack>
									<b>Additional Shipping Options</b>
									<HeaderActionButton onClick={() => setShowShippingOptionsModal(true)}>Change...</HeaderActionButton>
								</HorizontalPack>
							</AreaContentRow>
							<AreaContentRow>
								<ul>
									<li>{checkoutInitializeData.PackingBasisList.find(s => s.Value === checkoutEditableFields.ShippingChoices_PackingBasis)?.Label}</li>
									{userInfo && !!checkoutEditableFields.ShippingChoices_NotBeforeDate && !isSameDay(checkoutEditableFields.ShippingChoices_NotBeforeDate, todayDate) && <li>Do not ship before: {format(checkoutEditableFields.ShippingChoices_NotBeforeDate, DATE_FORMAT_DISPLAY)}</li>}
									{userInfo && checkoutEditableFields.ShippingChoices_IsRush && <li>Rush Processing</li>}
									{userInfo && checkoutEditableFields.ShippingChoices_IsCollect && <li>Shipping Collect: {checkoutEditableFields.ShippingChoices_CollectAccount}</li>}
									{userInfo && checkoutEditableFields.ShippingChoices_IsCollect && checkoutEditableFields.ShippingChoices_SelectedCollectCarrier && <li>Via: {checkoutEditableFields.ShippingChoices_SelectedCollectCarrier}</li>}
									{userInfo && preferredCarrier && <li>(Preferred: {preferredCarrier})</li>}
									{userInfo && checkoutEditableFields.ShippingChoices_SpecialPaperworkRequest !== "none" && <li>Documentation: {checkoutInitializeData.SpecialPaperworkKinds?.find(sp => sp.Value === checkoutEditableFields.ShippingChoices_SpecialPaperworkRequest)?.Label}</li>}
									{checkoutEditableFields.ShippingChoices_DeliveryInstructions && <li>Delivery Instructions: {checkoutEditableFields.ShippingChoices_DeliveryInstructions}</li>}
								</ul>
							</AreaContentRow>
						</>}
                    </>
                ))}
            </LayoutArea>
        </Area>
    )
}

